<template>
<div class="app">
    <el-card class="box-card">
        <div class="i-layout-content-main">
            <div>
            <div class="ivu-exception">
                <div class="ivu-exception-img">
                <div class="ivu-exception-img-element"></div>
                </div>
                <div class="ivu-exception-content">
                <h1>404</h1>
                <div class="ivu-exception-content-desc">抱歉，你访问的页面不存在</div>
                <div class="ivu-exception-content-actions">
                    <el-button type="primary" @click="link()">返回首页</el-button>
                </div>
                </div>
            </div>
            </div>
        </div>
   </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods:{
      link(){
          window.location.href="/";
      }
  }
};
</script>
<style scoped lang="scss">
.i-layout-content-main {
    margin: 24px;
    position: relative;
    .ivu-exception {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        .ivu-exception-img {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 62.5%;
            flex: 0 0 62.5%;
            width: 62.5%;
            padding-right: 12px;
            zoom: 1;
            .ivu-exception-img-element{
                float: right;
                width: 100%;
                max-width: 430px;
                height: 360px;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: contain;
                background-image: url("../../assets/img/icon/icon-404.svg");    
            }
            
        }
        .ivu-exception-content {
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            h1 {
                margin-bottom: 24px;
                color: #515a6e;
                font-weight: 600;
                font-size: 72px;
                line-height: 72px;
            }
            .ivu-exception-content-desc {
                margin-bottom: 16px;
                color: #808695;
                font-size: 20px;
                line-height: 28px;
            }
        }
    }
}
</style>
